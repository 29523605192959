<template>
  <el-container class="couponPack">
    <el-header class="header" height="2.70833vw">
      <div class="header-title">
        <div class="header-title-icon"></div>
        全部卡券
      </div>
    </el-header>
    <el-container class="main-container">
      <el-header class="main-header" height="3.125vw">
        <!-- 服务类型 -->
        <div class="radio">
          <p class="radio-label">类型:</p>
          <el-radio-group v-model="type" @change="handleTypeChange">
            <el-radio-button
              v-for="item in typeArr"
              :key="'type_' + item.value"
              :label="item.value"
              >{{ item.label }}</el-radio-button
            >
          </el-radio-group>
        </div>
        <!-- 状态 -->
        <div class="radio">
          <p class="radio-label">状态:</p>
          <el-radio-group v-model="state" @change="handleStateChange">
            <el-radio-button
              v-for="item in stateArr"
              :key="'state_' + item.value"
              :label="item.value"
              >{{ item.label }}</el-radio-button
            >
          </el-radio-group>
        </div>
      </el-header>
      <el-main class="main">
        <div class="coupo-list" v-if="list.length > 0">
          <CoupoCard
            class="coupo-list-item"
            v-for="item in list"
            :key="item.id"
            :info="item"
            :state="state"
          />
        </div>
        <div v-if="list.length < 1" class="noData">
          <img src="~a/workbench/coupo-noData.png" alt="" />
          <p>暂无可用券</p>
        </div>
        <el-pagination
          class="pagination"
          v-if="list.length > 0"
          background
          :current-page="page"
          :page-count="totalPage"
          @current-change="handleCurrentChange"
        />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import CoupoCard from './CouponCard.vue';
export default {
  name: 'CouponPack',
  components: {
    CoupoCard
  },
  data() {
    return {
      type: '',
      typeArr: [
        { label: '全部', value: '' },
        { label: '服务发布券', value: 1 }
        // { label: '抵扣券', value: 2 }
      ],
      state: 0,
      stateArr: [
        { label: '可使用', value: 0 },
        { label: '已过期', value: 1 }
      ],
      page: 1,
      totalPage: 1,
      list: []
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      const { type, state, page } = this;
      const params = {
        expire: state,
        type,
        page
      };
      this.$service.quota.couponMy(params).then(res => {
        const { status, data, totalPage, page } = res;
        if (status === 200) {
          this.list = data;
          this.page = page;
          this.totalPage = totalPage;
        }
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    handleTypeChange() {
      this.page = 1;
      this.getList();
    },
    handleStateChange() {
      this.page = 1;
      this.getList();
    }
  }
};
</script>

<style lang="less" scoped>
// 单选框
.radio {
  display: flex;
  align-items: center;
  margin-right: 200px;
  color: #333333;
  font-size: 20px;
  &:last-child {
    margin-right: 0;
  }
  &-label {
    margin-right: 20px;
  }

  /deep/ .el-radio-button {
    &:last-child {
      margin-right: 0;
    }
    &__inner {
      padding: 6px 27px;
      border: none;
      border-radius: 15px;
      box-shadow: none;
      color: #333333;
      font-size: 18px;
    }
    &.is-active {
      .el-radio-button__inner {
        background: #eaf2ff;
        color: #3d6eff;
      }
    }
  }
}

.coupo-list {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  height: calc(100% - 40px);
  overflow-y: auto;
  &-item {
    margin-right: 35px;
    margin-bottom: 32px;
  }
}

.noData {
  margin-top: 200px;
  text-align: center;
  color: #333;
  font-size: 14px;
  font-weight: bold;
  img {
    margin-bottom: 36px;
  }
}

.pagination {
  display: flex;
  justify-content: center;
}
</style>
