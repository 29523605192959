<template>
  <div
    class="coupon-card"
    :style="{ 'background-image': `url(${bg})`, color }"
    :class="state === 0 ? '' : 'disabled'"
  >
    <div class="coupon-card-left">
      <p class="coupon-card-name">{{ info.name }}</p>
      <p class="coupon-card-time">有效期至：{{ info.expireDateFormat }}</p>
    </div>
    <div class="coupon-card-right">
      <template v-if="info.type === 1">
        <p class="coupon-card-text">可使用</p>
        <p class="coupon-card-num">{{ info.canUseCount }}</p>
      </template>
      <template v-if="info.type === 2">
        <div class="coupon-card-num">
          <p class="coupon-card-unit">￥</p>
          <p>{{ info.price[0] }}</p>
        </div>
        <p class="coupon-card-text">满{{ info.price[1] }}可用</p>
      </template>
      <div v-if="state === 1" class="disabled">已过期</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CouponCard',
  props: {
    info: Object,
    state: Number
  },
  computed: {
    bg() {
      const { type } = this.info;
      const state = this.state;
      let bg = '';
      switch (type) {
        case 1:
          bg =
            state === 0
              ? require('a/workbench/coupon-fwfbq.png')
              : require('a/workbench/coupon-dkq-disabled.png');
          break;
        case 2:
          bg =
            state === 0
              ? require('a/workbench/coupon-dkq.png')
              : require('a/workbench/coupon-dkq-disabled.png');
          break;
      }
      return bg;
    },
    color() {
      const { type, state } = this.info;
      let color = '';
      switch (type) {
        case 1:
          color = '#B16307';
          break;
        case 2:
          color = state === 1 ? '#0F74FD' : '#999999';
          break;
      }
      return color;
    }
  }
};
</script>

<style lang="less" scoped>
.coupon-card {
  width: 332px;
  height: 122px;
  display: flex;
  justify-content: space-between;
  &.disabled {
    color: #999999 !important;
  }
  &-left {
    height: 100%;
    padding: 16px 0 0 22px;
  }
  &-right {
    position: relative;
    width: 110px;
    height: 100%;
    padding-top: 32px;
    text-align: center;
  }
  &-name {
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: bold;
  }
  &-time {
    display: inline-block;
    height: 30px;
    padding: 0 5px;
    line-height: 30px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    font-size: 16px;
  }
  &-text {
    font-size: 16px;
  }
  &-num {
    display: flex;
    align-items: baseline;
    justify-content: center;
    font-size: 30px;
    font-weight: bold;
  }
  &-unit {
    font-size: 16px;
    font-weight: normal;
  }
  .disabled {
    position: absolute;
    top: 42px;
    left: 0;
    transform: translateX(-50%);
    width: 73px;
    height: 30px;
    border: 1px dashed rgba(255, 0, 0, 0.5);
    border-radius: 2px;
    text-align: center;
    line-height: 30px;
    color: #ff3737;
    font-size: 18px;
  }
}
</style>
